import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
class Login extends Component {
  
constructor(props){
super(props);
const { match: { params } } = this.props;
    localStorage.setItem('udata','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjI4MzY2LCJleHAiOjE2MzE1MTY2MjB9.Bs5AMU9ck5Zms1TMTmOWdyRPknSzS_V_5tDvczrvZWQ')
    
this.state = {
username: '',
password: '',
redirectToReferrer: false
};

}
componentWillMount() {

    
    if(localStorage.getItem("udata") && localStorage.getItem("udata")!='undefined'){
     this.setState({redirectToReferrer: true});
      }
      
      else{
      this.setState({redirectToReferrer: false});
      }

  }

render() {

    if (this.state.redirectToReferrer) {
return (<Redirect to={'/my-courses'}/>)
}
return (
    <Redirect to={'http://elm.bidesh.com'}/>
)
}
}
export default Login;