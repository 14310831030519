import { siteConstants } from '../../constants'

import { actionconstants } from '../actionconstants'

import { getCookie } from '../../services/getCsrf'

const gettestdetail = (gl_id, course_id, unit_selected) => {
  return async (dispatch) => {
    dispatch(loadingCourseTestData())
    let r = await fetchCourseTest(gl_id, course_id, unit_selected)
    if (!r.success) {
      throw new Error('error')
    }
    const course_data = r
    dispatch(loadingCourseTestDataSuccess(course_data));
  }
}


const loadingCourseTestData = () => {
  return {
    type: actionconstants.LOADING_COURSE_TEST_DATA
  }
}
const fetchCourseTest = async (gl_id, course_id, unit_selected) => {
  let csrfToken = getCookie('csrfToken')
  let uri = siteConstants.API_URL + 'ajax/loadQuizzQuestionTest'
  let reqops = {
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
      'X-CSRF-TOKEN': csrfToken
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ id: gl_id, course_id: course_id, currentObj: { unit_id: unit_selected } })
  }
  let r = await fetch(uri, reqops).then((resp) => { return resp.json() })
  return r
}
const loadingCourseTestDataSuccess = (payload) => {
  return {
    type: actionconstants.LOADING_COURSE_TEST_DATA_SUCCESS,
    payload: payload,
  }
}

const setTestStarted = () => {
  return (dispatch) => {
    dispatch(testStartedSuccess())
  }
}
const testStartedSuccess = () => {
  return {
    type: actionconstants.COURSE_TEST_STARTED
  }
}

const answerSaveAnswerandSetCurrentQuestionlected = (answerObj, current_selected_question_index) => {
  return async (dispatch) => {
    if (answerObj.hasOwnProperty('answerid') && answerObj.answerid !== false) {
      dispatch(loadingNextQuestion())
      let r = await (saveQuizEachStep(answerObj))
      if (!r.success) {
        throw new Error("Error")
      }
    }
    dispatch(storeQuizEachStep(answerObj, current_selected_question_index))
  }
}

const loadingNextQuestion=()=>{
  return{
    type: actionconstants.COURSE_TEST_GET_NEXT_QUESTION
  }
}

const saveQuizEachStep = async (answerObj) => {
  let csrfToken = getCookie('csrfToken')
  let uri = siteConstants.API_URL + 'ajax/saveQuizEachStep'
  let reqops = {
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
      'X-CSRF-TOKEN': csrfToken
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify(answerObj)
  }
  let r = await fetch(uri, reqops).then((resp) => { return resp.json() })
  return r
}
const storeQuizEachStep = (answerObj, current_selected_question_index) => {
  return {
    type: actionconstants.COURSE_TEST_SAVE_ANSWER_AND_SET_QUESTION,
    payload: {
      "answerObj": answerObj,
      "current_selected_question_index": current_selected_question_index
    }
  }
}


const submitTest = (reqobj)=>{
  return async (dispatch)=>{
    dispatch(loadingSubmitTest())
    let r = await generateResult(reqobj)
    if(!r.success){
      throw new Error("Error")
    }
    dispatch(loadingSubmitTestSuccess(r))
  }
}

const loadingSubmitTest= () =>{
  return {
    type: actionconstants.COURSE_TEST_FINISHED
  }
}
const generateResult = async (answerObj) => {
  let csrfToken = getCookie('csrfToken')
  let uri = siteConstants.API_URL + 'ajax/saveQuizAnswerTest'
  let reqops = {
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
      'X-CSRF-TOKEN': csrfToken
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify(answerObj)
  }
  let r = await fetch(uri, reqops).then((resp) => { return resp.json() })
  return r
}
const loadingSubmitTestSuccess= (data) =>{
  return {
    type: actionconstants.COURSE_TEST_RESULT_GENERATED,
    payload: data
  }
}

const cancelTest=()=>{
  return (dispatch)=>{
    dispatch(cancelTestDispatch())
  }
}

const cancelTestDispatch=()=>{
  return{
      type: actionconstants.COURSE_TEST_CANCELLED
  }
}

export const courseTestAction = {
  gettestdetail,
  setTestStarted,
  answerSaveAnswerandSetCurrentQuestionlected,
  submitTest,
  cancelTest
}
