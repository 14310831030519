import { siteConstants } from '../../constants'
import { authorLmsconstants } from '../authorLmsconstants'
import { getCookie } from '../../services/getCsrf'
import { fetchDataWithCsrf } from '../../services/fetchDataWithCsrf'

const getCourseData = () => {
    return async (dispatch) => {
        dispatch(loadingCourseData())
        let r = await fetchData()
        if (!r.success) {
            throw new Error('error')
        }
        const all_courses = r.data
        dispatch(loadingCourseDataSuccess(all_courses));
    }
}

const fetchData = async () => {
    let course_id = localStorage.getItem('course_id')
    let r = await fetch(siteConstants.API_URL + '/AjaxCourseUpdatev2/getAllSections/' + course_id, { credentials: 'include' })
    let r1 = await r.json()
    return r1
}

const loadingCourseData = () => {
    return {
        type: authorLmsconstants.LOADING_AUTHOR_LMS_COURSE_DATA
    }
}

const loadingCourseDataSuccess = (payload) => {
    
    return {
        type: authorLmsconstants.LOADING_AUTHOR_LMS_COURSE_DATA_SUCCESS,
        payload: payload
    }
}

const openSection = (index) => {
    return ((dispatch) => {
        dispatch(openSectionSuccess(index))
    })
}

const openSectionSuccess = (index) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_SECTION_OPEN,
        payload: index
    }
}

const openChapter = (section_index, chapter_id, chapter_index, is_unit_loaded) => {
    if (is_unit_loaded) {
        return (dispatch) => {
            dispatch(openChapterSuccess(section_index, chapter_id, chapter_index, null))
        }
    }
    return async (dispatch) => {
        await dispatch(loadingChapterUnitData(chapter_id))
        let r = await fetchUnitData(chapter_id)
        if (!r.success) {
            throw new Error("Error from fetch unit")
        }
        dispatch(openChapterSuccess(section_index, chapter_id, chapter_index, r.data))
    }
}

const openChapterSuccess = (section_index, chapter_id, chapter_index, unit_data) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_CHAPTER_OPEN_SUCCESS,
        payload: {
            section_index: section_index,
            chapter_id: chapter_id,
            chapter_index: chapter_index,
            unit_data: unit_data
        }
    }
}
const loadingChapterUnitData = (chapter_id) => {
    return {
        type: authorLmsconstants.LOADING_AUTHOR_LMS_UNIT_DATA,
        payload: {
            loading_chapter_id: chapter_id
        }
    }
}

const fetchUnitData = async (chapter_id) => {
    let r = await fetch(siteConstants.API_URL + 'AjaxCourseUpdatev2/getActiveChapterUnits/' + chapter_id, { credentials: 'include' })
    let r1 = await r.json()
    return r1
}

const setSelectedData = (section_id, chapter_id, unit_id, chapter_to_be_shown) => {
    return (dispatch) => {
        dispatch(setAllData(section_id, chapter_id, unit_id, chapter_to_be_shown))
        //setClickUnit(section_id, chapter_id, unit_id)
    }
}
const setAllData = (section_id, chapter_id, unit_id, chapter_to_be_shown) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_SET_OPEN_DATA,
        payload: {
            section_id: section_id,
            chapter_id: chapter_id,
            unit_id: unit_id,
            chapter_to_be_shown: chapter_to_be_shown
        }
    }
}

const setClickUnit = (section_id, chapter_id, unit_id) => {
    let course_id = localStorage.getItem('course_id')
    fetchDataWithCsrf({ course_id: course_id, section_id: section_id, chapter_id: chapter_id, unit_id: unit_id }, 'AjaxCourseUpdatev2/clickUnit')
}

const getInitialDataOnLoad = () => {
    return async (dispatch) => {
        dispatch(loadingCourseData())
        let r = await fetchiInitialData()
        if (!r.success) {
            dispatch(initialDataLoadError())
        }
        dispatch(initialDataLoadSuccess(r.data))
    }
}
const fetchiInitialData = async () => {
    let course_id = localStorage.getItem('course_id')
    let r = await fetch(siteConstants.API_URL + '/AjaxCourseUpdatev2/getCourseSectionDataForInitialState/' + course_id, { credentials: 'include' })
    let r1 = await r.json()
    return r1
}
const initialDataLoadSuccess = (data) => {
    return {
        type: authorLmsconstants.LOADING_AUTHOR_LMS_COURSE_DATA_SUCCESS,
        payload: data
    }
}
const initialDataLoadError = () => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_USER_NOT_LOGGED_IN
    }
}

const completeUnit = (sec_id, chap_id, unit_id) => {
    return (async (dispatch) => {
        dispatch(completingUnit({ section_id: sec_id, chapter_id: chap_id, unit_id: unit_id }))
        let r = await unitMarkComplete(sec_id, chap_id, unit_id)
        if (!(r.success)) {
            throw new Error("error")
        }
        dispatch(unitCompleted({ section_id: sec_id, chapter_id: chap_id, unit_id: unit_id }))
    })
}

function completingUnit(obj) {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_UNIT_COMPLETE_LOADING,
        payload: obj
    }
}
async function unitMarkComplete(sec_id, chap_id, unit_id) {
    let csrfToken = getCookie('csrfToken')
    let course_id = localStorage.getItem('course_id')
    let uri = siteConstants.API_URL + 'AjaxCourseUpdatev2/completeUnit'
    let reqops = {
        headers: {
            'Content-Type': 'x-www-form-urlencoded',
            'X-CSRF-TOKEN': csrfToken
        },
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ section_id: sec_id, chapter_id: chap_id, unit_id: unit_id, course_id: course_id })
    }
    let r = await fetch(uri, reqops).then((resp) => { return resp.json() })
    return r
}
function unitCompleted(completeObj) {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_UNIT_COMPLETE,
        payload: completeObj
    }
}

const loadBatches = () => {
    return (async (dispatch) => {
        dispatch(batchLoading())
        let r = await getBatchInfo()
        if (r.success !== 1) {
            throw new Error()
        }
        dispatch(batchInfoSuccess(r.data))
    })
}
function batchLoading() {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_BATCH_LOADING
    }
}
async function getBatchInfo() {
    let course_id = localStorage.getItem('course_id')
    let url = 'AjaxCourseUpdatev2/getUserBatches'
    let r = await fetchDataWithCsrf({ course_id: course_id }, url)
    return r
}
function batchInfoSuccess(data) {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_BATCH_LOADING_COMPLETE,
        payload: data
    }
}

const loadBatchSessions = async (batch_id) => {

    let course_id = localStorage.getItem('course_id')
    let url = 'AjaxCourseUpdatev2/getCourseBatchSessionWithGroup/'
    let r = await fetchDataWithCsrf({ course_id: course_id, batch_id: batch_id }, url)
    return r
}

const getBatchSessions = (batch_id) => {
    return (async (dispatch) => {

        dispatch(batchSessionLoadng())
        let r = await loadBatchSessions(batch_id)
        if (r.success !== 1) {
            throw new Error("data not found")
        }
        dispatch(batchSessionLoadinComplete({ batch_id: batch_id, session_data: r.data }))
    })

}
const batchSessionLoadng = () => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_BATCH_SESSION_LOADING
    }
}
const batchSessionLoadinComplete = (data) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_BATCH_SESSION_LOADING_COMPLETE,
        payload: data
    }
}
const openSession = (batch_index, session_index) => {
    return ((dispatch) => {
        dispatch(sessionOpen({ batch_index: batch_index, session_index: session_index }))
    })
}
const sessionOpen = (data) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_BATCH_OPEN_SESSION,
        payload: data
    }
}
const setSessionVideo = (data) => {
    return ((dispatch) => {
        dispatch(setSessionPlayVideo(data))
    })
}
const setSessionPlayVideo = (data) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_COURSE_SESSION_VIDEO_SET,
        payload: data
    }
}
const setVideoTime = (time, file_content_id, section_selected, chapter_selected, unit_selected) => {
    return (async (dispatch) => {
        let r = await updateVideoTime(time, file_content_id)
        if (parseInt(r.success) === 1) {
            dispatch(updateVideoTimeState(r.data, file_content_id, section_selected, chapter_selected, unit_selected))
        }

    })
}
const updateVideoTime = async (time, file_content_id) => {
    let r = await fetchDataWithCsrf({ 'video_time': time, 'file_content_id': file_content_id }, 'AjaxCourseUpdatev2/updateVideoTime')
    return r
}
const updateVideoTimeState = (time, file_content_id, section_selected, chapter_selected, unit_selected) => {
    return {
        type: authorLmsconstants.AUTHOR_LMS_SET_VIDEO_TIME,
        payload: {
            time: time,
            file_content_id: file_content_id,
            section_selected: section_selected,
            chapter_selected: chapter_selected,
            unit_selected: unit_selected
        }
    }
}
export const authorLmsAction = {
    getCourseData,
    openSection,
    openChapter,
    setSelectedData,
    getInitialDataOnLoad,
    completeUnit,
    loadBatches,
    getBatchSessions,
    openSession,
    setSessionVideo,
    setVideoTime
}