import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { fetchDataWithCsrf } from "../services/fetchDataWithCsrf";
import { useSelector,useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { myaccountAction } from "../redux/actions/myaccountAction";

const MobileVerification = () => {
  const Label = () => {
    return (
      <p style={{ fontSize: '12px', marginTop: '3px', marginLeft: '-2px' }}>Receive updates in WhatsApp</p>
    )
  }

  const dispatch = useDispatch()

  const history = useHistory()

  const userAccount = useSelector(state => state.myaccount)

  const [showVerificationModal, setShowVerificationModal] = useState(false)

  const [verificationState, setVerificationState] = useState(0)

  const [enableSkip, setEnableSkip] = useState(true)

  const [otpValue, setOtpValue] = useState()

  const [mobileNumber, setMobileNumber] = useState("");

  const [isdCode, setIsdCode] = useState("+91");

  const [countryCode, setCountryCode] = useState([]);

  const [saveMobileLoading, setSaveMobileLoading] = useState(false)

  const [checkOtpLoading, setCheckOtpLoading] = useState(false)

  const [chekedWhatsApp, setChekedWhatsApp] = useState(true);

  const [msg, setMsg] = useState("")

  const [isIndia, setIsIndia] = useState()
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  useEffect(() => {
    let is_open_popup = true
    if (!userAccount.user_data_loading) {
      if (userAccount.user_data[0].is_author || userAccount.user_data[0].is_affiliate) {
        let otp_info = sessionStorage.getItem("otpInfo");
        if (otp_info === null) {
          history.push('/otpscreen')
        }
        setEnableSkip(false)
      }

      if (!userAccount.user_data[0].is_mobile_verified) {
        let skip_date = localStorage.getItem('skip_date')
        if (skip_date !== null) {
          let dt = new Date()
          let t = dt.getTime()
          if ((t - parseInt(skip_date)) < 0) {
            is_open_popup = false
          }
        }
      } else if (userAccount.user_data[0].is_mobile_verified) {
        is_open_popup = false
      }
      if (is_open_popup) {
        getCountryCode()
        setShowVerificationModal(true)
        setVerificationState(0)
      }
      if (parseInt(userAccount.user_data[0].mobile) > 0) {
        setMobileNumber(userAccount.user_data[0].mobile)
      }

      if (userAccount.user_data[0].isd_code !== null && userAccount.user_data[0].isd_code !== "") {
        setIsdCode(userAccount.user_data[0].isd_code)
        if(userAccount.user_data[0].isd_code === "+91"){
          setIsIndia(true)
        }
        else{
          setIsIndia(false)
        }
      }

    }
  }, [userAccount.user_data_loading])
  const getCountryCode = async () => {
    let url = "ajax/getCountryWithCode";
    let r = await fetchDataWithCsrf({}, url);
    setCountryCode(r.data);
  };

  const saveMobileNo = async (e) => {
    e.preventDefault()
    try {
      setSaveMobileLoading(true)
      let r = await fetchDataWithCsrf(
        {
          mobile: mobileNumber,
          isd_code: isdCode,
        },
        "login/saveMobileNumber"
      );
      if (r.success === true) {
        setSaveMobileLoading(false)
        setVerificationState(2)
      }
    } catch (error) {
      setSaveMobileLoading(false)
    }
  }

  const verifyMobile = async (e) => {
    e.preventDefault()
    try {
      setCheckOtpLoading(true)
      let r = await fetchDataWithCsrf(
        {
          mobile_verification_code: otpValue,
          chekedWhatsApp: chekedWhatsApp
        },
        "login/matchMobileVerification"
      );
      if (r.success === true) {
        setMsg("Mobile number verified")
        setShowSuccessMsg(true)
        resetModal()
        let u_info = sessionStorage.getItem("userInfo");
        let u = JSON.parse(u_info);
        u[0].is_mobile_verified = true
        u[0].mobile = mobileNumber
        sessionStorage.setItem("userInfo", JSON.stringify(u));
        dispatch(myaccountAction.getUserDetails())
      } else {
        setMsg("Invalid OTP")
      }
      setCheckOtpLoading(false)

    } catch (error) {
      setCheckOtpLoading(false)
    }
  }
  const [isResendCode, setIsResendCode] = useState(false)

  const resendVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      setIsResendCode(true);
      let url = "login/resendOtp";
      let r = await fetchDataWithCsrf({}, url);
      if (r.success === true) {
        setIsResendCode(false);
        setMsg(r.msg)
      }
    } catch (err) {
      setIsResendCode(false);
    }
  };

  const skipVerify = () => {
    setShowVerificationModal(false)
      let dt = new Date()
      let t = dt.getTime() + (86400000 * 7)
      localStorage.setItem('skip_date', t)
  }

  const changeIsdCode = (e) =>{
    setMobileNumber(null)
    if(e.target.value === '+91'){
      setIsIndia(true)
    }
    else{
      setIsIndia(false)
    }
    setIsdCode(e.target.value)
  }

  const resetModal = () => {
    setTimeout(function () {
      setShowVerificationModal(false);
    }, 3000);
  };
  return (

    <Modal
      show={showVerificationModal}
      onHide={() => setShowVerificationModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <Modal.Title>
                {
                  !showSuccessMsg && verificationState === 0 && <span>Please confirm your mobile number</span>
                }
                {
                  !showSuccessMsg && verificationState === 2 && <span>Enter OTP Sent to xxxxxxx{mobileNumber.toString().substring((mobileNumber.toString().length - 4))}</span>
                }
              </Modal.Title>
            </div>
          </div>
        </div>

      </Modal.Header>
      <Modal.Body>
        <p>{showVerificationModal}</p>
        {
          !showSuccessMsg && verificationState === 0 &&
          <Form
            onSubmit={(e) => saveMobileNo(e)}
          >
            <div className="row">
              <div className="col-lg-4">
                <Form.Group controlId="isdCode">
                  <Form.Control
                    as="select"
                    value={isdCode}
                    name="isd_code"
                    onChange={(e) => {
                      changeIsdCode(e);
                    }}
                  >
                    {countryCode.map((code) => (
                      <option value={code.isd_code}>
                        {code.sortname + "(" + code.isd_code + ")"}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-8">
              <Form.Group controlId="MobileNumber">
                {isIndia &&
                
                <Form.Control
                  type="text"
                  name="mobile"
                  placeholder="Enter Mobile number"
                  required
                  onChange={(e) => setMobileNumber(e.target.value)}
                  value={mobileNumber}
                  pattern="[1-9]{1}[0-9]{9}"
                  autoComplete="off"
                />
             
                }
                {
                  !isIndia &&
                  <Form.Control
                  type="text"
                  name="mobile"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  minLength={6}
                  maxLength={14}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  pattern="[0-9.]+"
                  required
                 />
                }
                 </Form.Group>
                
              </div>
            </div>
            <div className="col-12">
              <div className="text-center">
                <button type="submit" className="btn elm-btn elm-btn-primary" disabled={saveMobileLoading ? true : false}>
                  {!saveMobileLoading ? <span>Save</span> : <span>
                    <i className="fa fa-circle-o-notch fa-spin"></i> </span>}
                </button>
              </div>
            </div>
          </Form>
        }

        {
          !showSuccessMsg && verificationState === 2 &&
          <Form onSubmit={(e) => verifyMobile(e)}>
            <div className="row">
              <div className="col-lg-12">
                <Form.Control
                  type="text"
                  name="mobile_code"
                  placeholder="Enter OTP"
                  required
                  onChange={(e) => setOtpValue(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="mt-3">
                  <Form.Check
                    id="custom-switch"
                    type="switch"
                    label={<Label />}
                    checked={chekedWhatsApp}
                    onChange={(ev) => setChekedWhatsApp(ev.target.checked)}
                  />
                </div>
              </div>
              <div className="col-lg-5 text-right mt-3">
                <span className="small" style={{ cursor: 'pointer' }} onClick={(e) => resendVerifyOTP(e)}>
                  {
                    !isResendCode ? 'Resend' : <i className="fa fa-circle-o-notch fa-spin"></i>
                  }
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="text-center">
                <button type="submit" className="btn elm-btn elm-btn-primary" disabled={checkOtpLoading ? true : false}>
                  {!checkOtpLoading ? <span>Verify</span> : <span>
                    <i className="fa fa-circle-o-notch fa-spin"></i></span>}
                </button>
              </div>
            </div>
          </Form>
        }
        {
          showSuccessMsg &&
          <>
          <div className="text-center">
          <i className="fa fa-check check_icon_mobile_validation" aria-hidden="true"></i>
          <p className="mobile_paragraph">{msg}</p>
          </div>
          </>
        }
        {
          !showSuccessMsg && msg.length > 0 && <span>{msg}</span>
        }
        {
          enableSkip && <span style={{ cursor: 'pointer', color: '#959595', fontSize: '12px', float: 'right' }} onClick={() => skipVerify()}>Skip</span>
        }
      </Modal.Body>
    </Modal>
  )
}

export default MobileVerification