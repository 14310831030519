import React, { useEffect, useState } from 'react'

import Layout from './Layout';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import Webinar from './Element/Webinar';
import { myaccountAction } from './redux/actions/myaccountAction'
import { webinarPlanAction } from './redux/actions/webinarPlanAction'
import Modal from 'react-bootstrap/Modal'
import Rating from './components/Rating';
import emptyWebinar from './empty-webinar.png';
import ErrorModal from './Element/ErrorModal'
import Loader from "./Element/Loader"
import { siteConstants } from "./constants";
const MyWebinars = () => {
  const [show, setShow] = useState(false)

  const [currentCourseObj, setCurrentCourseObj] = useState(null)
  const [isError, setIsError] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleOpenRating = (data) => {
    setCurrentCourseObj(data)
    setShow(true)

  }
  const myaccount = useSelector(state => state.myaccount)
  const dispatch = useDispatch()
  let pageData = myaccount.my_webinar_paging
  const plansData = useSelector(state => state.webinarplan)
  useEffect(() => {
    getWebinarPlan()
    userUpcomingWebinar()
    getUserWebinar(1)

  }, [])
  const userUpcomingWebinar = async () => {
    try {
      await dispatch(
        myaccountAction.getMyUpcomingWebinars()
      );
    } catch (err) {
      setIsError(true)
      throw new Error(err)
    }
  }
  const getWebinarPlan = () => {
    try {
      dispatch(webinarPlanAction.getUserPlansDetails())
    } catch (error) {
      alert("error")
    }
  }
  const getUserWebinar = async (currentpage) => {
    try {
      await dispatch(
        myaccountAction.getMyWebinars(currentpage)
      );
    } catch (err) {
      throw new Error(err)
    }
  }

  const getUserWebinarNextPage = async (currentpage) => {
    try {
      await dispatch(
        myaccountAction.getMyWebinarPaging(currentpage)
      );
    } catch (err) {
      throw new Error(err)
    }
  }


  const handlePagination = () => {
    if (pageData.nextPage) {
      getUserWebinarNextPage(pageData.page + 1)
    }

  }
  let h = window.screen.availHeight
  return (

    <Layout nameprefix="My" name="Webinars">

      <div className="row">
        {!plansData.user_webinar_plan_loading && plansData.user_webinar_plan_list.currentsubscription != null && (


          <div className="col-lg-12 mb-4">
            <div className="section-header">
              <p>Plans and <span className="blue-text">Subscription</span></p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <table className="webinar-plan-table table-responsive">
                  <tr>
                    <th>Plan Name</th>
                    <th>Expiry Date</th>
                    <th>Used</th>
                    <th>Available</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      <span className="plan-name"><img className="mr-1" src={plansData.user_webinar_plan_list.currentsubscription != null ? plansData.user_webinar_plan_list.currentsubscription.subscription_plan.plan_image : ''} style={{ width: '20px' }} />
                        {plansData.user_webinar_plan_list.currentsubscription != null ? plansData.user_webinar_plan_list.currentsubscription.subscription_plan.name : ''}</span>
                    </td>
                    <td>{plansData.user_webinar_plan_list.currentsubscription.expiry_date}</td>
                    <td>{plansData.user_webinar_plan_list.currentsubscription.no_purchased_webinars}</td>
                    <td>{plansData.user_webinar_plan_list.currentsubscription.no_available_webinars}</td>
                    <td className="text-center">
                      {plansData.user_webinar_plan_list.currentsubscription.expiry_diff>-1 &&
                        (
                          <Link
                            to={`/webinar-plan/`}
                            className="view-plan-btn" style={{ color: 'white' }}>Book Webinar
                          </Link>

                        )

                      }
                      {plansData.user_webinar_plan_list.currentsubscription.is_renew &&
                        (
                          <a className="view-plan-btn" style={{background: '#ee9949', color: '#fff', border: 'none', fontSize: '.8rem',marginLeft:'5px'}} href={siteConstants.API_URL+'webinar-plans'} target="_blank">Renew</a>
                        )

                      }
                    </td>
                  </tr>

                </table>
              </div>
            </div>

          </div>
        )
        }
        {!plansData.user_webinar_plan_loading && plansData.user_webinar_plan_list.currentsubscription == null && (myaccount.my_upcoming_webinar_data.length > 0 || myaccount.my_webinar_data.length > 0) &&
          <div className="col-lg-12 mb-4">
            <div className="section-header">

              <p>Plans and <span className="blue-text">Subscription</span></p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="when-no-webinar-plan">
                  <p className="mb-0 pt-2"><span>You have not subscribed to any of our plans, yet. </span> <a className="view-plan-btn pull-right" href={`${siteConstants.API_URL + '\webinar-plans'}`} target="_blank" style={{ color: 'white' }}>View Plans</a></p>
                </div>               
              </div>
            </div>
          </div>
        }

        {!myaccount.loading && myaccount.my_upcoming_webinar_data.length > 0 &&
          (
            <div className="col-lg-12 mb-4">

              <div className="section-header">
                <p>upcoming <span className="blue-text">WEBINARS</span></p>
              </div>
              <div className="row">
                {myaccount.my_upcoming_webinar_data
                  .map(function (feedData, index) {


                    return (

                      <Webinar
                        webinarData={feedData}
                        openReviewPopup={handleOpenRating} upcomingWebinar={true}
                      />


                    )

                  }, this)
                }

              </div>
            </div>
          )
        }
        {(!myaccount.loading && myaccount.my_upcoming_webinar_data.length === 0) && (!myaccount.firstwebinarloading && myaccount.my_webinar_data.length === 0) &&
          <div className="col-lg-12 mb-4 text-center">
            <div className="empty-section-top-box" style={{ height: h / 2 }}>
              <div className="empty-section-inner-box" style={{ marginTop: (h / 4) - 25 }}>
                <img src={emptyWebinar} alt="logo" className="img-fluid empty-page-img" />
                <p className="empty-page-text">
                  Join and learn from webinars by popular market experts.
                  </p>
                <a href={`${siteConstants.API_URL + '/webinars'}`} target="_blank" style={{ color: 'white' }} className="elm-primary-btn">
                  View Webinars
                  </a>
              </div>
            </div>
          </div>
        }
        {!myaccount.firstwebinarloading && myaccount.my_webinar_data.length > 0 &&
          (
            <div className="col-lg-12 mb-4">
              <div className="section-header">
                <p>PAST <span className="blue-text">WEBINARS</span></p>
              </div>
              <div className="row">

                {
                  myaccount.my_webinar_data.map(function (feedData, index) {
                    return (
                      <Webinar
                        webinarData={feedData}
                        openReviewPopup={handleOpenRating} upcomingWebinar={false}
                      />

                    )

                  }, this)
                }
                {pageData.nextPage &&
                  <div className="col-lg-12">
                    {!myaccount.pageloading ? <p className="text-right">
                      <button className="show-more-btn show-more-btn-center" onClick={handlePagination}>
                        more <i className="fa fa-angle-double-down" aria-hidden="true" />
                      </button>
                    </p> : <p className="text-right"><button className="show-more-btn show-more-btn-center">
                      Loading <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />
                    </button></p>
                    }
                  </div>
                }
              </div>
            </div>
          )
        }

        <Modal show={show} onHide={handleClose} animation={false} className="custom-modal">
          <Modal.Header closeButton>
            {currentCourseObj &&

              <Modal.Title>{currentCourseObj.name}</Modal.Title>

            }
          </Modal.Header>
          <Modal.Body>
            {
              currentCourseObj &&
              <Rating item_id={currentCourseObj.id} modalClosed={handleClose} item_type={4} />
            }
          </Modal.Body>
        </Modal>
      </div>
      {
        (myaccount.firstwebinarloading || myaccount.loading) && !isError &&
        <Loader is_loading={(myaccount.firstwebinarloading || myaccount.loading) && !isError} />
      }
      {
        isError &&
        <ErrorModal
          error={isError}
        />
      }
    </Layout>

  )
}

export default MyWebinars;