import { getCookie } from './getCsrf'

import { siteConstants } from '../constants'

export async function fetchDataWithCsrf(data, url, header = null) {
    let csrfToken = getCookie('csrfToken')
    let uri = siteConstants.API_URL + url
    let h = {
        'X-CSRF-TOKEN': csrfToken
    }
    if (header) {
        h = {
            header,
            'X-CSRF-TOKEN': csrfToken
        }
    }
    let reqops = {
        headers: h,
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data)
    }
    let r = await fetch(uri, reqops).then((resp) => { return resp.json() })
    return r
}