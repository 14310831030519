import React from "react";
import { siteConstants } from "../constants";
import logo from "../new_elm_logo.png";
import smallLogo from "../small-elm-logo.png";

function Header(props) {
  const logout = () => {
    sessionStorage.clear();
    window.location.href = siteConstants.API_URL + "/login/logout";
  };
  return (
    <div className="container-fluid pl-0 pr-0 overflow-hidden">
      <div className="top-header">
        <div className="row">
          <div className="col-lg-2 col-4 text-center">
            <a
              href="#"
              onClick={() => props.onMobileMenuClick(true)}
              className="left-menu-open-btn"
            >
              <i class="fa fa-bars" aria-hidden="true"></i>
            </a>
            <div className="elm-logo">
              <a href={siteConstants.API_URL} target="_blank">
                <img src={logo} alt="logo" className="img-fluid lms-elm-icon" />
                <img
                  src={smallLogo}
                  alt="logo"
                  className="img-fluid logo-for-mobile"
                />
              </a>
            </div>
          </div>
          <div
            className="col-lg-10 col-8"
            style={{ boxShadow: "rgb(243 243 243) 8px 0px 8px inset" }}
          >
            <div className="help-settings">
              <span className="course-webinar-link-top">
                <a href={siteConstants.API_URL + "courses"} target="_blank">
                  Courses
                </a>
              </span>
              <span className="course-webinar-link-top">
                <a href={siteConstants.API_URL + "webinars"} target="_blank">
                  Webinars
                </a>
              </span>
              <span></span>
              <span className="go-to-my-account-dashboard">
                <a href="#" style={{cursor: 'pointer' }} onClick={() => logout()}><span>Logout</span><i class="fa fa-power-off" aria-hidden="true"></i></a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
