import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { siteConstants } from '../constants';
import { getCookie } from '../services/getCsrf';
const pixelRatio = window.devicePixelRatio || 1;

export default function UploadProfilePhoto(props) {
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropDone, setCropDone] = useState(false);
    const previewCanvasRef = useRef(null);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const [fileSeleted, setFileSeleted] = useState(false)
    const [imageUploading, setImageUploading] = useState(false)

    const [crop, setCrop] = useState({
        unit: "px",
        width: 300,
        height: 300,
        aspect: 16 / 9,
    });
    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setCropDone(false);
            setFileSeleted(true)
        }
    };
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let response_data
        if (completedCrop !== null) {
            const canvas = previewCanvasRef.current;
            let imageBlob = await new Promise((resolve) =>
                canvas.toBlob(resolve, "image/png")
            );
            formData.append("file_data", imageBlob, "profile_image.png");
            let csrfToken = getCookie("csrfToken");
            let reqops = {
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
                credentials: "include",
                body: formData,
            };
            setImageUploading(true)
            response_data = await fetch(
                siteConstants.API_URL + 'student/home/uploadProfileImage',
                reqops
            )
                .then((res) => res.json())
                .then((s) => { return s });
            let u_info = JSON.parse(sessionStorage.getItem('userInfo'))
            u_info[0].photo = response_data.data
            sessionStorage.setItem('userInfo', JSON.stringify(u_info))
            props.getUserData()
            props.onclose()
            setImageUploading(false)
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onclose}
                onEscapeKeyDown={props.onclose}
            >
                <Modal.Header>
                    Upload picture
                </Modal.Header>
                <Modal.Body>
                    {
                        !imageUploading &&
                        <Form onSubmit={handleSubmit}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={onSelectFile}
                                style={{ display: 'block' }}
                            />
                            {!cropDone && fileSeleted && (
                                <div style={{textAlign: 'center'}}>
                                    <ReactCrop
                                        src={upImg}
                                        onImageLoaded={onLoad}
                                        crop={crop}
                                        onChange={(c) => setCrop(c)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        locked
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCropDone(true);
                                        }}
                                        className="elm-btn elm-btn-primary"
                                        style={{paddingTop: '6px'}}
                                    >
                                        Crop
                        </button>
                                </div>
                            )}
                            <div style={{ textAlign: 'center' }}>

                                <canvas
                                    ref={previewCanvasRef}
                                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0),
                                    }}
                                />
                            </div>
                            {
                                cropDone &&
                                <div style={{ textAlign: 'center' }}> <button className="elm-btn elm-btn-primary"  style={{paddingTop: '6px'}} type="submit">Save</button></div>
                            }
                        </Form>
                    }
                    {
                        imageUploading && <span>Uploading Image</span>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
