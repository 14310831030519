import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ellipse from "../Ellipse.png";
import Moment from "moment";
import ReactTooltip from "react-tooltip";
function Webinar(props) {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const feedData = props.webinarData;
  return (
    <div className="col-lg-4 ">
      <div className="card custom-learning-card">
        <div className="learning-card-left-img">
          <img lazy alt="" src={feedData.webinar.image}  className="img-fluid"/>
        </div>
        <div className="learning-card-right-containt">
          <img src={ellipse} className="ellipse" />
          <p className="mb-0">
            <span className="learning-name"> {feedData.webinar.name.length < 60 ? feedData.webinar.name : feedData.webinar.name.substring(0,60)+'...'}</span>
          </p>
          <div className="row">
            <div className="col-lg-5 col-12" style={{ paddingLeft: "4px" }}>
              {!props.upcomingWebinar && (
                <span
                  className="webinar-date"
                  onClick={() => props.openReviewPopup(feedData.webinar)}
                  style={{ cursor: "pointer" }}
                  data-tip="Add a review for this"
                >
                  <i class="fa fa-comment" aria-hidden="true"></i> Review
                </span>
              )}
              {props.upcomingWebinar && (
                <span className="webinar-date">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                  {Moment(feedData.webinar.w_date).utc().format("Do MMM")}
                </span>
              )}
            </div>
            {feedData.webinar.upload_docs && (
              <div className="col-lg-7 col-12" style={{ paddingLeft: "4px" }}>
                {!props.upcomingWebinar && (
                  <a
                    href={feedData.webinar.upload_docs}
                    download
                    data-tip="Download webinar documents shared"
                  >
                    <span className="webinar-time">
                      <i class="fa fa-file-zip-o" aria-hidden="true"></i>{" "}
                      Download
                    </span>
                  </a>
                )}
                {props.upcomingWebinar && (
                  <span className="webinar-time">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {Moment(feedData.webinar.w_date).utc().format("hh:mm")}-
                    {Moment(feedData.webinar.end_time).utc().format("hh:mm a")}
                  </span>
                )}
              </div>
            )}
          </div>
          <p className="unit-time-card">
            {!props.upcomingWebinar && (
              <span>
                {(feedData.webinar.upload_webinar_video_transcoded != null ||
                  feedData.webinar.youtube_link !== "" || feedData.webinar.upload_webinar_video != null) && (
                  <Link to={`/meeting/${feedData.webinar.id}`}>
                    Watch Again
                  </Link>
                )}
                {(feedData.webinar.upload_webinar_video_transcoded === null ||
                  feedData.webinar.upload_webinar_video_transcoded ===
                    "null") && feedData.webinar.upload_webinar_video === null && 
                  feedData.webinar.youtube_link === "" && (
                    <a
                      href="javascript:void(0)"
                      style={{
                        backgroundColor: "#6787bf",
                        borderRadius: "5px",
                      }}
                      data-tip="Upload video in progress"
                    >
                      Recording Awaited
                    </a>
                  )}
              </span>
            )}
            {props.upcomingWebinar && (
              <Link
                to={`/meeting/${feedData.webinar.id}`}
                data-tip="Click to join the live session"
              >
                Join{" "}
              </Link>
            )}
          </p>
        </div>
      </div>
      <ReactTooltip className="mytooltip" />
    </div>
  );
}

export default Webinar;
